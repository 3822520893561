import { useEffect, useRef } from "react";
import { gsap } from "gsap";

import Card from "./Card";

function Projectspage() {
  const textRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, delay: 0 },
    );
  }, []);

  return (
    <div ref={textRef} className="flex-grow flex flex-col items-center justify-center gap-y-4">
      <Card
        thumbnail={"https://avatars.githubusercontent.com/u/90649313?v=4"}
        thumbnailShape={"circle"}
        title={"This website"}
        description={
          <p>
          Self explanatory.
          </p>
        }
        footer={"React, Tailwind CSS, GSAP"}
      />

      <Card
        thumbnail={
          <div className="w-32 h-32 flex-shrink-0 flex items-center justify-center">
            <h1>
              No icon just yet!
            </h1>
          </div>
        }
        thumbnailShape={"circle"}
        title={"4Vault - WIP"}
        description={
          <p>
          A self hosted MERN-TS stack cloud storage service.
          </p>
        }
        footer={"MERN-TS, Mocha, Chai"}
      />

      <Card
        thumbnail={
          <div className="w-32 h-32 flex-shrink-0 flex items-center justify-center">
            <h1>
              No icon just yet!
            </h1>
          </div>
        }
        thumbnailShape={"circle"}
        title={"Foobar Subsonic Component - WIP"}
        description={
          <p>
            A simple Foobar2000 component that allows for connection to and streaming from a Subsonic server directly within the player.
          </p>
        }
        footer={"C++, Foobar2000 SDK, Subsonic API, cURL, FFmpeg"}
      />

      <Card
        thumbnail="/assets/icons/ganyuIcon.webp"
        thumbnailShape="circle"
        title="GanyuBot"
        thumbnailUrl="https://github.com/4v0n/GanyuBot"
        titleUrl={"https://github.com/4v0n/GanyuBot"}
        description={
          <div>
            <p>
              A very simple discord bot written using Java and making use of JDA, Lavaplayer and Morphia.
            </p>
            <br/>
            <p>
              EOS but working on a version 2!
            </p>
            <br/>
            Features:
            <ul className="list-disc list-inside ml-0">
              <li>Booru image search.</li>
              <li>Blackjack minigame with per-server leaderboards.</li>
              <li>Youtube music player with spotify conversion.</li>
              <li>Extremely extensible with modular commands, automatic command tree formation and isolated features.</li>
            </ul>
          </div>
        }
        footer="Java, JDA, Morphia, Lavaplayer"
      />

      <Card
        thumbnail="/assets/icons/spendingTrackerIcon.webp"
        thumbnailShape="circle"
        title="Spending Tracker"
        thumbnailUrl="https://github.com/muhsin7/spending-tracker"
        titleUrl={"https://github.com/muhsin7/spending-tracker"}
        description={
          <div>
            <p>
              A spending tracker, allowing for tracking of spending with gamification through achievements.
            </p>
            <br/>
            <p>
              Worked in a team of 9, working on the backend. Got to work with express, mongoose and mocha for testing.
            </p>
            <br/>
          </div>
        }
        footer="MERN, Mocha, Chai"
      />

      <Card
        thumbnail="/assets/icons/kiloWordCompleteIcon.webp"
        thumbnailShape="circle"
        title="Kilo Word Completion Extension"

        description={
          <div>
            <p>
            A simple extension of antirez's Kilo text editor to support word completion as a part of my university dissertation project.
            </p>
            <br/>
            <p>
              Made use of C and wrote trie and list structures from scratch with the necessary manipulation, traversal, search and sorting algorithms.
            </p>
            <br/>
            Features:
            <ul className="list-disc list-inside ml-0">
              <li>Prefix match word completion using a trie structure.</li>
              <li>Fuzzy match word completion using a trie and levenshtein distance.</li>
              <li>Added support for LaTeX editing.</li>
            </ul>
          </div>
        }
        footer={"C, Termios, CUnit"}
      />
    </div>
  );
}

export default Projectspage;